import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class LineItemService {
  async getLineItems(accountId) {
    try {
      const lineItems = await api.get('definitions/line_items/list/' + accountId);
      return convertKeysToCamelCase(lineItems.data.line_item_defs);
    } catch (error) {
      console.error('Could not line items:', error);
    }
  }
}

export default LineItemService;
