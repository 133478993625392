import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class MetricService {
  async getMetrics(accountId) {
    try {
      const metrics = await api.get('definitions/metrics/list/' + accountId);
      return convertKeysToCamelCase(metrics.data.metric_defs);
    } catch (error) {
      console.error('Could not fetch metrics:', error);
    }
  }
}

export default MetricService;
