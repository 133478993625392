import React from 'react';
import Select from 'react-select';

const ObjectTypeDropdown = ({ objectTypes, tableName, onChange }) => {
  const objectTypeOptions = objectTypes.map((objectType) => ({
    value: objectType.id,
    label: objectType.title,
  }));

  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : '', tableName);
  };

  return (
    <Select
      options={objectTypeOptions}
      onChange={handleChange}
      isClearable
      placeholder="Select an object type"
    />
  );
};

export default ObjectTypeDropdown;
