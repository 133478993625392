import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class AssemblyService {
  async getAssemblies(accountId) {
    try {
      const assemblies = await api.get('definitions/assemblies/list/' + accountId);
      return convertKeysToCamelCase(assemblies.data.assembly_defs);
    } catch (error) {
      console.error('Could not fetch assemblies:', error);
    }
  }
}

export default AssemblyService;
