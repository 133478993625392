import React, { useState, useMemo, useCallback } from 'react';
import AccountDropdown from '../AccountDropdown/AccountDropdown';
import ObjectTypeDropdown from '../ObjectTypeDropdown/ObjectTypeDropdown';
import ComponentGroupDropdown from '../ComponentGroupDropdown/ComponentGroupDropdown';
import ColumnChecklist from '../ColumnChecklist/ColumnChecklist';
import WildCardSearch from '../WildcardSearch/WildcardSearch';
import './ComparisonTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataMapService from '../../services/DataMapService';

const dataMapService = new DataMapService();

const ComparisonTable = ({
  accountsAll,
  objectTypes,
  componentGroupsAll,
  assemblies,
  componentGroups,
  components,
  fieldGroups,
  fields,
  lineItems,
  metrics,
  tableData,
  tableName,
  otherTableData,
  dataMaps,
  otherTableDataMaps,
  columnConfig,
  visibleColumns,
  defaultVisibleColumns,
  handleColumnToggle,
  handleDragStart,
  handleDragEnter,
  handleDragLeave,
  handleDragEnd,
  handleDrop,
  draggedItem,
  dropTarget,
  handleAccountChange,
  selectedAccountId,
  handleObjectTypeChange,
  selectedObjectTypeId,
  handleComponentGroupChange,
  selectedComponentGroupId,
  toggleMatchSourceTitle,
  toggleApprove,
  toggleDismiss,
  createAndToggleDismiss,
  updateDataMaps,
  handleTitle,
  handleRemoveDataMap,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');
  const [showMatched, setShowMatched] = useState(false);
  const [showUnmatched, setShowUnmatched] = useState(false);
  const [showLineItemsOnly, setShowLineItemsOnly] = useState(false);
  const [showComponentsOnly, setShowComponentsOnly] = useState(false);
  const [showNeedsApproval, setShowNeedsApproval] = useState(false);
  const [showDismissed, setShowDismissed] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  const isNestedDisplayType = objectTypes.find(
    (type) => type.id === selectedObjectTypeId
  )?.displayType === 'nested';

  const baseDefs = objectTypes.find(
    (type) => type.id === selectedObjectTypeId
  )?.baseDefs

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const processComponents = useCallback((componentsData, allComponentsArray, allLineItemsArray, nestingLevel = 1) => {
    const findComponentById = (id) => allComponentsArray.find(c => c.id === id);
    const findLineItemById = (id) => allLineItemsArray.find(li => li.id === id);
  
    const process = (data, level) => {
      // Sort the data first by orderIndex before reducing it
      const sortedData = data.sort((a, b) => {
        // Find components to get their orderIndex
        const compA = findComponentById(a.id);
        const compB = findComponentById(b.id);
        return (compA?.orderIndex || 0) - (compB?.orderIndex || 0);
      });
  
      return sortedData.reduce((results, compData) => {
        const component = findComponentById(compData.id);
        if (!component) {
          console.error(`Component with ID ${compData.id} not found.`);
          return results;
        }
        results.push({ ...component, nestingLevel: level });
    
        if (compData.lineItems && compData.lineItems !== "[]") {
          const lineItemIds = JSON.parse(compData.lineItems);
          // Sort line items by orderIndex before adding them
          const sortedLineItems = lineItemIds
            .map(findLineItemById)
            .filter(li => li) // Filter out any undefined line items
            .sort((a, b) => (a.orderIndex || 0) - (b.orderIndex || 0));
  
          sortedLineItems.forEach(lineItem => {
            results.push({ ...lineItem, nestingLevel: level + 1 });
          });
        }
    
        if (compData.components && compData.components !== "[]" && level < 3) {
          const nestedComponentIds = JSON.parse(compData.components);
          // Find and sort nested components before processing them
          const nestedComponentsData = nestedComponentIds
            .map(findComponentById)
            .filter(comp => comp) // Filter out any undefined components
            .sort((a, b) => (a.orderIndex || 0) - (b.orderIndex || 0));
  
          results.push(...process(nestedComponentsData, level + 1));
        }
    
        return results;
      }, []);
    };
    
    return process(componentsData, nestingLevel);
  }, []);  

  const filteredData = useMemo(() => {
    let processedData = [];

    if (isNestedDisplayType && baseDefs === 'fieldGroups') {
      const sortedFieldGroups = fieldGroups.sort((a, b) => a.orderIndex - b.orderIndex);
    
      sortedFieldGroups.forEach(group => {
        const fieldsArray = JSON.parse(group.fields);
    
        processedData.push({
          ...group,
          nestingLevel: 1
        });
    
        if (Array.isArray(fieldsArray)) {
          const nestedFields = fieldsArray
            .map(fieldId => tableData.find(field => field.id === fieldId))
            .filter(field => field != null)
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .map(field => ({
              ...field,
              nestingLevel: 2
            }));
    
          processedData.push(...nestedFields);
        }
      });
    } else if (isNestedDisplayType && baseDefs === 'components') {
      const topLevelComponents = components.filter(comp => 
        Boolean(comp.isNested) === false && comp.componentGroupDefId === selectedComponentGroupId
      );
      
      const sortedTopLevelComponents = topLevelComponents.sort((a, b) => 
        Number(a.orderIndex) - Number(b.orderIndex)
      );
      
      sortedTopLevelComponents.forEach(componentData => {
        processedData.push(...processComponents([componentData], components, lineItems));
      });
    } else {
      processedData = [...tableData].sort((a, b) => a.orderIndex - b.orderIndex)
    }

    let filtered = processedData;

    if (searchTerm !== '') {
      const regex = new RegExp(searchTerm.replace('*', '.*'), 'i');
      filtered = processedData.filter((item) => {
        return (
          regex.test(item.id.toString()) ||
          regex.test(item.table) ||
          regex.test(item.title) ||
          regex.test(item.description) ||
          regex.test(item.notes)
        );
      });
    }

    if (showMatched) {
      filtered = filtered.filter((item) => {
        const matchingDataMap = dataMaps.find(dataMap => dataMap.defId === item.id && dataMap.defTable === item.table && dataMap.matchId);
        const matchingOtherTableDataMap = otherTableDataMaps.find(dataMap => dataMap.matchId === item.id && dataMap.defTable === item.table);
        const isMatchingRefId = otherTableData.some(otherItem => otherItem.refId === item.refId && item.refId !== null && item.refId !== '');
        return matchingDataMap || matchingOtherTableDataMap || isMatchingRefId;
      });
    }    
  
    if (showUnmatched) {
      filtered = filtered.filter((item) => {
        const matchingDataMap = dataMaps.find(dataMap => dataMap.defId === item.id && dataMap.defTable === item.table && dataMap.matchId);
        const matchingOtherTableDataMap = otherTableDataMaps.find(dataMap => dataMap.matchId === item.id && dataMap.defTable === item.table);
        const isMatchingRefId = otherTableData.some(otherItem => otherItem.refId === item.refId && item.refId !== null && item.refId !== '');
        return !matchingDataMap && !matchingOtherTableDataMap && !isMatchingRefId;
      });
    }    

    if (showLineItemsOnly) {
      filtered = filtered.filter((item) => item.table === "line_item_defs");
    }
  
    if (showComponentsOnly) {
      filtered = filtered.filter((item) => item.table === "component_defs");
    }

    if (showNeedsApproval) {
      filtered = filtered.filter((item) => {
        const matchingDataMap = dataMaps.find(dataMap => dataMap.defId === item.id && dataMap.defTable === item.table && dataMap.matchId);
        return matchingDataMap && !matchingDataMap.approve && !matchingDataMap.dismiss;
      });
    }
  
    if (!showDismissed) {
      filtered = filtered.filter((item) => {
        const matchingDataMap = dataMaps.find(dataMap => dataMap.defId === item.id && dataMap.defTable === item.table);
        return !matchingDataMap || !matchingDataMap.dismiss;
      });
    }    

    if (!showDeleted) {
      filtered = filtered.filter((item) => item.deletedAt === null);
    }
  
    return filtered;
  }, [tableData, isNestedDisplayType, baseDefs, fieldGroups, components, lineItems, selectedComponentGroupId, processComponents, otherTableData, searchTerm, showUnmatched, showMatched, showLineItemsOnly, showComponentsOnly, showNeedsApproval, showDismissed, showDeleted, dataMaps, otherTableDataMaps]);
  
  // eslint-disable-next-line no-unused-vars
  const sortedData = useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig]);

   // eslint-disable-next-line no-unused-vars
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const [editingNoteId, setEditingNoteId] = useState(null);
  const [notes, setNotes] = useState({});

  const handleNotesDoubleClick = (item) => {
    setEditingNoteId(item.id);
    if (!notes[item.id]) {
      setNotes({ ...notes, [item.id]: item.dataMap?.notes || '' });
    }
  };

  const handleNoteChange = (event, item) => {
    setNotes({ ...notes, [item.id]: event.target.value });
  };

  const handleNoteSave = async (item, event) => {
    if (event.type === 'blur' || (event.type === 'keydown' && event.key === 'Enter')) {
      const currentNote = notes[item.id];
  
      let dataMapToUpdateOrCreate;
  
      const existingDataMap = dataMaps.find(dataMap => dataMap.defId === item.id);

      if (existingDataMap) {
        dataMapToUpdateOrCreate = {
          ...existingDataMap,
          notes: currentNote
        };
  
        const updatedMap = await dataMapService.updateDataMap(dataMapToUpdateOrCreate);
        if (updatedMap) {
          updateDataMaps(updatedMap, tableName);
        }
      } else {
        dataMapToUpdateOrCreate = {
          accountId: selectedAccountId,
          defTable: item.table,
          defId: item.id,
          notes: currentNote
        };

        const newMap = await dataMapService.createDataMap(dataMapToUpdateOrCreate);
  
        if (newMap) {
          updateDataMaps(newMap, tableName);
        }
      }
  
      setEditingNoteId(null);
      setNotes(prevNotes => ({ ...prevNotes, [item.id]: currentNote }));
    }
  };

  return (
    <div>
      <div className={`filter-container ${tableName === "left" ? "source-table-bg" : ""}`}>
        <div className="filter-row">
          <AccountDropdown accountsAll={accountsAll} tableName={tableName} onChange={handleAccountChange} />
          <ObjectTypeDropdown objectTypes={objectTypes} tableName={tableName} onChange={handleObjectTypeChange} />
          <ComponentGroupDropdown
            componentGroupsAll={componentGroupsAll}
            tableName={tableName}
            selectedAccountId={selectedAccountId}
            selectedComponentGroupId={selectedComponentGroupId}
            onChange={handleComponentGroupChange}
          />
          <ColumnChecklist 
            tableName={tableName}
            columnConfig={columnConfig}
            visibleColumns={visibleColumns}
            defaultVisibleColumns={defaultVisibleColumns}
            handleColumnToggle={handleColumnToggle}
          />
          <WildCardSearch searchTerm={searchTerm} handleSearch={handleSearch} />
        </div>
        <div className="checkbox-row">
          <label>
            <input 
              name="notes"
              type="checkbox" 
              checked={showMatched} 
              onChange={() => setShowMatched(!showMatched)} 
            />
            Matched
          </label>
          <label>
            <input  
              name="unmatched"
              type="checkbox" 
              checked={showUnmatched} 
              onChange={() => setShowUnmatched(!showUnmatched)} 
            />
            Unmatched
          </label>
          <label>
            <input  
              name="lineItemsOnly"
              type="checkbox" 
              checked={showLineItemsOnly} 
              onChange={() => setShowLineItemsOnly(!showLineItemsOnly)} 
            />
            Line Items Only
          </label>
          <label>
            <input 
              name="componentsOnly"
              type="checkbox" 
              checked={showComponentsOnly} 
              onChange={() => setShowComponentsOnly(!showComponentsOnly)} 
            />
            Components Only
          </label>
          <label>
            <input
              name="needsApproval"
              type="checkbox" 
              checked={showNeedsApproval} 
              onChange={() => setShowNeedsApproval(!showNeedsApproval)} 
            />
            Needs Approval
          </label>
          <label>
            <input
              name="includeDismissed"
              type="checkbox" 
              checked={showDismissed} 
              onChange={() => setShowDismissed(!showDismissed)} 
            />
            Include Dismissed
          </label>
          <label>
            <input 
              name="includeDeleted"
              type="checkbox" 
              checked={showDeleted} 
              onChange={() => setShowDeleted(!showDeleted)} 
            />
            Include Deleted
          </label>
        </div>
      </div>
      <div className="table-container-2">
        <table className={`table-wrapper ${tableName === "left" ? "source-table-bg" : ""}`} id={tableName}>
          <thead className="table-header">
            <tr>
              <th></th>
              {columnConfig.map((col) => {
                if (visibleColumns[col.key]) {
                  return (
                    <th
                      key={col.key}
                      /* onClick={() => requestSort(col.key)} */
                      /* className="clickable-header" */
                    >
                      {col.label}
                    </th>
                  );
                }
                return null;
              })}
            </tr>
          </thead>
          <tbody>
            {selectedAccountId && selectedObjectTypeId && selectedComponentGroupId ? (
              filteredData.map((item, index) => {
                const matchingDataMap = dataMaps.find(dataMap => 
                  dataMap.accountId === selectedAccountId && 
                  dataMap.defId === item.id && 
                  dataMap.defTable === item.table
                );

                const matchingOtherTableDataMap = otherTableDataMaps.find(dataMap =>
                  dataMap.matchId === item.id &&
                  dataMap.defTable === item.table
                );
              
                const isMatchingRefId = otherTableData.some(
                  otherItem => otherItem.refId === item.refId &&
                  item.refId !== null &&
                  item.refId !== '');

                const matchingOtherItem = otherTableData.find(
                  otherItem => otherItem.refId === item.refId &&
                  item.refId !== null &&
                  item.refId !== ''
                );

                return (
                  <tr
                    key={`${item.type}-${item.id}-${index}`} {...item}
                    draggable={tableName === "right"}
                    onDragStart={(e) => tableName === "right" ? handleDragStart(e, item, tableName, matchingDataMap) : null}
                    onDragEnter={(e) => handleDragEnter(e, item)}
                    onDragLeave={(e) => handleDragLeave(e, item)}
                    onDragEnd={handleDragEnd}
                    onDrop={(e) => handleDrop(e, item, tableName)}
                    onDragOver={(e) => e.preventDefault()}
                    className={[
                      draggedItem?.id === item.id ? 'dragging' : '',
                      dropTarget?.id === item.id ? 'drop-target' : '',
                      `nesting-level-${item.nestingLevel || 0}`
                    ].join(' ').trim()}
                  >
                    <td style={{ borderLeft: `${3 * (item.nestingLevel || 0)}px solid black`, minWidth: '0px', padding: 0 }}></td>
                    {columnConfig.map((col) => 
                      visibleColumns[col.key] ? (
                        <td key={col.key} onDoubleClick={() => col.key === 'notes' && handleNotesDoubleClick(item)}>
                          {(() => {
                            switch (col.key) {
                              case 'match': {

                                return (
                                  <>
                                    {(matchingDataMap && matchingDataMap.matchId) || matchingOtherTableDataMap || isMatchingRefId ? (
                                      <FontAwesomeIcon icon="check-circle" color="green" />
                                    ) : (
                                      <FontAwesomeIcon icon="times-circle" color="red" />
                                    )}
                                    {matchingDataMap && (
                                      <FontAwesomeIcon 
                                        icon="trash-alt" 
                                        color="red"
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                        onClick={() => {
                                          if (window.confirm(`Are you sure you want to delete this item with ID ${matchingDataMap.id}?`)) {
                                            handleRemoveDataMap(matchingDataMap.id, matchingDataMap, tableName);
                                          }
                                        }}
                                      />
                                    )}
                                  </>
                                );
                              }
                              case 'assembly':
                                if (item.assemblyId) {
                                  const assembly = assemblies.find(asm => asm.id === item.assemblyId);
                                  return assembly ? assembly.title : '-';
                                }
                                return '-';
                              case 'matchTitle':
                                let matchTitle = '-';
                                
                                if (matchingDataMap && matchingDataMap.matchId) {
                                  matchTitle = matchingDataMap.title;
                                } else {
                                  if (matchingOtherItem) {
                                    matchTitle = matchingOtherItem.title; 
                                  }
                                }
                                
                                return matchTitle;
                              case 'notes':
                                return editingNoteId === item.id ? (
                                  <input
                                    name="notes"
                                    type="text"
                                    value={notes[item.id]}
                                    onChange={(event) => handleNoteChange(event, item)}
                                    onBlur={(event) => handleNoteSave(item, event)}
                                    onKeyDown={(event) => handleNoteSave(item, event)}
                                    autoFocus
                                  />
                                ) : (
                                  matchingDataMap ? matchingDataMap.notes : '-'
                                );
                              case 'needsApproval':
                                if (matchingDataMap && matchingDataMap.matchId) {
                                  if (!matchingDataMap.approve && !matchingDataMap.dismiss) {
                                    return 'Yes';
                                  }
                                }
                                return '-';                              
                              case 'matchedByUserName':
                                return matchingDataMap ? matchingDataMap.matchedByUserName : '-';
                              case 'matchedAt':
                                return matchingDataMap ? matchingDataMap.matchedAt : '-';
                              case 'matchSourceTitle':
                                return matchingDataMap && matchingDataMap.matchId ? (
                                  <input
                                    name="matchSourceTitle"
                                    type="checkbox"
                                    checked={matchingDataMap.matchSourceTitle}
                                    onChange={() => toggleMatchSourceTitle(item.id, matchingDataMap, tableName)}
                                  />
                                ) : '-';                                                             
                              case 'approve':
                                return matchingDataMap && matchingDataMap.matchId ? (
                                  <input
                                    name="approve"
                                    type="checkbox"
                                    checked={matchingDataMap.approve}
                                    onChange={() => toggleApprove(item.id, matchingDataMap, tableName)}
                                  />
                                ) : '-';     
                              case 'dismiss':
                                return !isMatchingRefId && !matchingDataMap && !matchingOtherTableDataMap ? (
                                  <input
                                    name="dismiss"
                                    type="checkbox"
                                    checked={matchingDataMap ? matchingDataMap.dismiss : false}
                                    onChange={() => {
                                      if (matchingDataMap) {
                                        toggleDismiss(item.id, matchingDataMap, tableName);
                                      } else {
                                        createAndToggleDismiss(item, tableName);
                                      }
                                    }}
                                  />
                                ) : '-'; 
                              default:
                                return item[col.key] || '-';
                            }
                          })()}
                        </td>
                      ) : null
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="100">
                  Please select at least an Account, Object Type, and Component Group to view data.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComparisonTable;
