import React from 'react';
import Select from 'react-select';

const ComponentGroupDropdown = ({ 
  componentGroupsAll, 
  tableName, 
  onChange, 
  selectedAccountId,
  selectedComponentGroupId,
}) => {
  const filteredComponentGroupsAll = componentGroupsAll.filter(
    (componentGroup) => componentGroup.accountId === selectedAccountId && componentGroup.deletedAt === null
  );

  const componentGroupAllOptions = filteredComponentGroupsAll.map((componentGroup) => ({
    value: componentGroup.id,
    label: componentGroup.title,
  }));

  const selectedOption = componentGroupAllOptions.find(option => option.value === selectedComponentGroupId);

  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : null, tableName);
  };

  return (
    <Select
      options={componentGroupAllOptions}
      value={selectedOption} // Set the selected option here
      onChange={handleChange}
      placeholder="Select a component group"
      isClearable
    />
  );
};

export default ComponentGroupDropdown;
