import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class DataMapService {
  async getDataMaps(accountId) {
    try {
      const dataMaps = await api.get('data_maps/' + accountId);
      return convertKeysToCamelCase(dataMaps.data.data_maps);
    } catch (error) {
      console.error('Could not fetch data maps:', error);
    }
  }

  async createDataMap(dataMapToCreate) {
    try {
      const response = await api.post('data_maps/process', dataMapToCreate);
      return convertKeysToCamelCase(response.data.data_map);
    } catch (error) {
      console.error('Error occurred while creating data map:', error);
      return null;
    }
  }

  async updateDataMap(dataMapToUpdate) {
    try {
      const response = await api.put('data_maps/process', dataMapToUpdate);
      return convertKeysToCamelCase(response.data.data_map);
    } catch (error) {
      console.error('Error occurred while updating data map:', error);
      return null;
    }
  }

  async removeDataMap(dataMapId) {
    try {
      const response = await api.remove('data_maps/' + dataMapId);
      return response.message;
    } catch (error) {
      console.error('Could not destroy data map:', error);
    }
  }
}

export default DataMapService;
