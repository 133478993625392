import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class ComponentGroupService {
  async getComponentGroups(accountId = null) {
    try {
      let endpoint = 'definitions/component_groups/list';
      if (accountId !== null) {
        endpoint += `/${accountId}`;
      }
      const componentGroups = await api.get(endpoint);
      return convertKeysToCamelCase(componentGroups.data.component_group_defs);
    } catch (error) {
      console.error('Could not fetch component groups:', error);
    }
  }
}

export default ComponentGroupService;
