import axios from 'axios';
import AuthService from '../services/AuthService';

const authService = new AuthService();

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  headers: {
    'Authorization': `Bearer ${authService.getAuthToken()}`
  }
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

const handleErrors = (error) => {
  if (error.response.status === 401) {
    console.log('Unauthorized');
  } else if (error.response.status === 500) {
    console.log('Internal Server Error');
  } else {
    console.log('An unknown error occurred.');
  }

  return Promise.reject(error);
};

export const get = async (endpoint) => {
  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const post = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const put = async (endpoint, data) => {
  try {
    const response = await api.put(endpoint, data);
    return response.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const remove = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response.data;
  } catch (error) {
    handleErrors(error);
  }
};
