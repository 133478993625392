import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class FieldService {
  async getFields(accountId) {
    try {
      const fields = await api.get('definitions/fields/list/' + accountId);
      return convertKeysToCamelCase(fields.data.field_defs);
    } catch (error) {
      console.error('Could not fetch fields:', error);
    }
  }
}

export default FieldService;
