import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class ComponentService {
  async getComponents(accountId) {
    try {
      const components = await api.get('definitions/components/list/' + accountId);
      return convertKeysToCamelCase(components.data.component_defs);
    } catch (error) {
      console.error('Could not fetch components:', error);
    }
  }
}

export default ComponentService;
