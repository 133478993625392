import React, { useState, useEffect, useRef } from 'react';
import './ColumnChecklist.css';

const ColumnChecklist = ({ tableName, columnConfig, visibleColumns, handleColumnToggle, defaultVisibleColumns }) => {
  const [showColumns, setShowColumns] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowColumns(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleClick = () => {
    setShowColumns(!showColumns);
  };

  const handleReset = () => {
    handleColumnToggle(tableName, null, defaultVisibleColumns);
  };

  const handleSelectAll = () => {
    const allVisible = Object.keys(visibleColumns).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    handleColumnToggle(tableName, null, allVisible);
  };

  const handleClose = () => {
    setShowColumns(false);
  };

  return (
    <div className="column-toggle-container" ref={dropdownRef}>
      <button onClick={handleToggleClick}>
        {showColumns ? "Hide Columns" : "Show Columns"}
      </button>

      <div className={`column-toggle-overlay ${showColumns ? 'active' : ''}`}>
        <form className="column-toggle-form">
          {Object.keys(visibleColumns).map((key) => {
            const column = columnConfig.find((col) => col.key === key);
            const label = column ? column.label : key;
            return (
              <div key={key}>
                <input
                  type="checkbox"
                  id={`${tableName}-${key}`}
                  checked={visibleColumns[key]}
                  onChange={() => handleColumnToggle(tableName, key)}
                />
                <label htmlFor={`${tableName}-${key}`}>{label}</label>
              </div>
            );
          })}
          <button type="button" onClick={handleSelectAll}>
            Show All
          </button>
          <button type="button" onClick={handleReset}>
            Reset
          </button>
          <button type="button" onClick={handleClose}>
            Close
          </button>
        </form>
      </div>
    </div>
  );
};

export default ColumnChecklist;
