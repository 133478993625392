import React, { useState } from 'react';
import Select from 'react-select';

const AccountDropdown = ({ accountsAll, tableName, onChange }) => {
  const [selectedAccountId, setSelectedAccountId] = useState('');

  const accountOptions = accountsAll.map((account) => ({
    value: account.id,
    label: account.title,
  }));

  const handleAccountChange = (selectedOption) => {
    const accountId = selectedOption ? selectedOption.value : '';
    setSelectedAccountId(accountId);
    onChange(accountId, tableName);
  };

  return (
    <Select
      options={accountOptions}
      value={accountOptions.find((option) => option.value === selectedAccountId)}
      onChange={handleAccountChange}
      isClearable
      placeholder={tableName === "left" ? "Select a SOURCE account" : "Select a TARGET account"} 
    />
  );
};

export default AccountDropdown;
