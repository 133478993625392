import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class FieldGroupService {
  async getFieldGroups(accountId) {
    try {
      const fieldGroups = await api.get('definitions/field_groups/list/' + accountId);
      return convertKeysToCamelCase(fieldGroups.data.field_group_defs);
    } catch (error) {
      console.error('Could not fetch field groupss:', error);
    }
  }
}

export default FieldGroupService;
