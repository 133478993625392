import React from 'react';

const WildCardSearch = ({ searchTerm, handleSearch }) => {
  const handleClearClick = () => {
    handleSearch('');
  };

  return (
    <div>
      <label htmlFor="wildcard-search">Search: </label>
      <input
        type="text"
        id="wildcard-search"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <button onClick={handleClearClick}>Clear</button>
    </div>
  );
};

export default WildCardSearch;
