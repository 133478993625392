import * as api from '../utils/api';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

class AccountService {
  async getAccounts() {
    try {
      const accounts = await api.get('accounts/list');
      return convertKeysToCamelCase(accounts.data.accounts);
    } catch (error) {
      console.error('Could not fetch accounts:', error);
    }
  }
}

export default AccountService;
