function snakeToCamel(str) {
  return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
}

export function convertKeysToCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => convertKeysToCamelCase(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        snakeToCamel(key),
        convertKeysToCamelCase(value),
      ])
    );
  }
  return obj;
}
