const environment = {
  authUrl: process.env.REACT_APP_AUTH_URL,
  environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
};

export default class AuthService {
  constructor() {
    this.cookiePrefix = this.getCookiePrefix();
  }

  isLoggedIn() {
    const authToken = this.getCookie(`${this.cookiePrefix}auth_token`);
    const authUser = this.getCookie(`${this.cookiePrefix}auth_user`);
    return !!authToken && !!authUser;
  }

  redirectToAuth(message = '') {
    const redirectUrl = window.location.href;
    const authUrl = `${environment.authUrl}?redirect=${encodeURIComponent(redirectUrl)}&message=${encodeURIComponent(message)}`;
    window.location.href = authUrl;
  }

  getCookiePrefix() {
    return `${environment.environmentName}_`;
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const cookieName = `${name}=`;
    const parts = value.split(cookieName);
    if (parts.length === 2) {
      return decodeURIComponent(parts.pop().split(';').shift() || '');
    }
    return null;
  }

  getAuthToken() {
    return this.getCookie(`${this.cookiePrefix}auth_token`);
  }  
}
