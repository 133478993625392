import React, { useState, useEffect } from 'react';
import AuthService from './services/AuthService';
import AccountService from './services/AccountService';
import AssemblyService from './services/AssemblyService';
import ComponentGroupService from './services/ComponentGroupService';
import ComponentService from './services/ComponentService';
import DataMapService from './services/DataMapService';
import FieldService from './services/FieldService';
import FieldGroupService from './services/FieldGroupService';
import LineItemService from './services/LineItemService';
import MetricService from './services/MetricService';
import ComparisonTable from './components/ComparisonTable/ComparisonTable';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faInfoCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './App.css';

library.add(faCheckCircle, faTimesCircle, faInfoCircle, faTrashAlt);

const objectTypes = [
  { id: 'assemblies', title: 'Assemblies', table: 'assembly_defs', displayType: 'single' },
  { id: 'componentGroups', title: 'Component Groups', table: 'component_group_defs', displayType: 'single' },
  /* { id: 'components', title: 'Components', table: 'component_defs', displayType: 'nested', baseDefs: 'components' }, */
  { id: 'fieldGroups', title: 'Field Groups', table: 'field_group_defs', displayType: 'single' },
  { id: 'fields', title: 'Fields', table: 'field_defs', displayType: 'nested', baseDefs: 'fieldGroups' },
  { id: 'lineItems', title: 'Line Items', table: 'line_item_defs', displayType: 'nested', baseDefs: 'components' },
  { id: 'metrics', title: 'Metrics', table: 'metric_defs', displayType: 'single' },
];

const columnConfig = [
  { key: 'id', label: 'ID' },
  { key: 'table', label: 'Table' },
  { key: 'title', label: 'Title and Structure' },
  { key: 'assembly', label: 'Assembly' },
  { key: 'match', label: 'Matched' },
  { key: 'matchTitle', label: 'Match Title' },
  { key: 'notes', label: 'Notes About This' },
  { key: 'needsApproval', label: 'Needs Approval' },
  { key: 'matchedByUserName', label: 'Matched By' },
  { key: 'matchedAt', label: 'Matched Date' },
  { key: 'matchSourceTitle', label: 'Match Source Title' },
  { key: 'approve', label: 'Approved' },
  { key: 'dismiss', label: 'Dismissed' },
  { key: 'refId', label: 'Ref ID' },
  { key: 'createdAt', label: 'Created At' },
  { key: 'updatedAt', label: 'Updated At' },
  { key: 'deletedAt', label: 'Deleted At' },
  { key: 'description', label: 'Description' },
];

const defaultVisibleColumns = {
  id: true,
  table: true,
  title: true,
  assembly: true,
  match: true,
  matchTitle: true,
  notes: true,
  needsApproval: true,
  matchedByUserName: true,
  matchedAt: true,
  matchSourceTitle: true,
  approve: true,
  dismiss: true,
  refId: false,
  createdAt: false,
  updatedAt: false,
  deletedAt: false,
  description: false,
};

const accountService = new AccountService();
const assemblyService = new AssemblyService();
const authService = new AuthService();
const componentService = new ComponentService();
const componentGroupService = new ComponentGroupService();
const dataMapService = new DataMapService();
const fieldService = new FieldService();
const fieldGroupService = new FieldGroupService();
const lineItemService = new LineItemService();
const metricService = new MetricService();

const App = () => {
  useEffect(() => {
    if (!authService.isLoggedIn()) {
      authService.redirectToAuth('You must be logged in to access this service.');
    }
  }, []);

  const [accountsAll, setAccountsAll] = useState([]);

  useEffect(() => {
    const fetchAccountsAll = async () => {
      try {
        const fetchedAccountsAll = await accountService.getAccounts();
        setAccountsAll(fetchedAccountsAll);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAccountsAll();
  }, []);

  const [componentGroupsAll, setComponentGroupsAll] = useState([]);

  useEffect(() => {
    const fetchComponentGroupsAll = async () => {
      try {
        const fetchedComponentGroupsAll = await componentGroupService.getComponentGroups();
        setComponentGroupsAll(fetchedComponentGroupsAll);
      } catch (error) {
        console.log(error);
      }
    };

    fetchComponentGroupsAll();
  }, []);
  
  const [selectedAccountIdLeft, setSelectedAccountIdLeft] = useState(null);
  const [selectedAccountIdRight, setSelectedAccountIdRight] = useState(null);
  const [assembliesLeft, setAssembliesLeft] = useState([]);
  const [assembliesRight, setAssembliesRight] = useState([]);
  const [componentGroupsLeft, setComponentGroupsLeft] = useState([]);
  const [componentGroupsRight, setComponentGroupsRight] = useState([]);
  const [componentsLeft, setComponentsLeft] = useState([]);
  const [componentsRight, setComponentsRight] = useState([]);
  const [dataMapsLeft, setDataMapsLeft] = useState([]);
  const [dataMapsRight, setDataMapsRight] = useState([]);
  const [fieldsLeft, setFieldsLeft] = useState([]);
  const [fieldsRight, setFieldsRight] = useState([]);
  const [fieldGroupsLeft, setFieldGroupsLeft] = useState([]);
  const [fieldGroupsRight, setFieldGroupsRight] = useState([]);
  const [lineItemsLeft, setLineItemsLeft] = useState([]);
  const [lineItemsRight, setLineItemsRight] = useState([]);
  const [metricsLeft, setMetricsLeft] = useState([]);
  const [metricsRight, setMetricsRight] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);

  const handleInfoModalToggle = () => {
    setInfoModalOpen(!isInfoModalOpen);
  };

  const updateDataMaps = (dataMap, tableName) => {
    if (tableName === 'left') {
      setDataMapsLeft(prevDataMaps => {
        const index = prevDataMaps.findIndex(dm => dm.id === dataMap.id);
        if (index > -1) {
          return [
            ...prevDataMaps.slice(0, index),
            dataMap,
            ...prevDataMaps.slice(index + 1)
          ];
        } else {
          return [...prevDataMaps, dataMap];
        }
      });
    } else if (tableName === 'right') {
      setDataMapsRight(prevDataMaps => {
        const index = prevDataMaps.findIndex(dm => dm.id === dataMap.id);
        if (index > -1) {
          return [
            ...prevDataMaps.slice(0, index),
            dataMap,
            ...prevDataMaps.slice(index + 1)
          ];
        } else {
          return [...prevDataMaps, dataMap];
        }
      });
    }
  };
  
  useEffect(() => {
  }, [selectedAccountIdLeft]);
  
  useEffect(() => {
  }, [selectedAccountIdRight]);
  
  const handleAccountChange = (accountId, tableName) => {
    if (tableName === "left") {
      setSelectedAccountIdLeft(accountId);

      if (accountId) {
        assemblyService.getAssemblies(accountId).then((data) => {
          setAssembliesLeft(data);
        });
        componentGroupService.getComponentGroups(accountId).then((data) => {
          setComponentGroupsLeft(data);

          const firstValidComponentGroupLeft = data.find(group => group.deletedAt === null);

          if (firstValidComponentGroupLeft) {
            handleComponentGroupChange(firstValidComponentGroupLeft.id, 'left');
          }
        });
        componentService.getComponents(accountId).then((data) => {
          setComponentsLeft(data);
        });
        dataMapService.getDataMaps(accountId).then((data) => {
          setDataMapsLeft(data);
        });
        fieldService.getFields(accountId).then((data) => {
          setFieldsLeft(data);
        });
        fieldGroupService.getFieldGroups(accountId).then((data) => {
          setFieldGroupsLeft(data);
        });
        lineItemService.getLineItems(accountId).then((data) => {
          setLineItemsLeft(data);
        });
        metricService.getMetrics(accountId).then((data) => {
          setMetricsLeft(data);
        });
     }
    } else {
      setSelectedAccountIdRight(accountId);
      
      if (accountId) {
        assemblyService.getAssemblies(accountId).then((data) => {
          setAssembliesRight(data);
        });
        componentGroupService.getComponentGroups(accountId).then((data) => {
          setComponentGroupsRight(data);

          const firstValidComponentGroupRight = data.find(group => group.deletedAt === null);

          if (firstValidComponentGroupRight) {
            handleComponentGroupChange(firstValidComponentGroupRight.id, 'right');
          }
        });
        componentService.getComponents(accountId).then((data) => {
          setComponentsRight(data);
        });
        dataMapService.getDataMaps(accountId).then((data) => {
          setDataMapsRight(data);
        });
        fieldService.getFields(accountId).then((data) => {
          setFieldsRight(data);
        });
        fieldGroupService.getFieldGroups(accountId).then((data) => {
          setFieldGroupsRight(data);
        });
        lineItemService.getLineItems(accountId).then((data) => {
          setLineItemsRight(data);
        });
        metricService.getMetrics(accountId).then((data) => {
          setMetricsRight(data);
        });
      }
    }
  };

  const [selectedObjectTypeIdLeft, setSelectedObjectTypeIdLeft] = useState(null);
  const [selectedObjectTypeIdRight, setSelectedObjectTypeIdRight] = useState(null);

  useEffect(() => {
  }, [selectedObjectTypeIdLeft]);
  
  useEffect(() => {
  }, [selectedObjectTypeIdRight]);

  const handleObjectTypeChange = (objectTypeId, tableName) => {
    if (tableName === "left") {
      setSelectedObjectTypeIdLeft(objectTypeId);
    } else {
      setSelectedObjectTypeIdRight(objectTypeId);
    }
  };

  const getTableData = (selectedAccount, selectedObjectType, side) => {
    if (selectedAccount && selectedObjectType) {
      switch (selectedObjectType) {
        case 'lineItems':
          return side === 'left' ? lineItemsLeft : lineItemsRight;
        case 'componentGroups':
          return side === 'left' ? componentGroupsLeft : componentGroupsRight;
        case 'components':
          return side === 'left' ? componentsLeft : componentsRight;
        case 'fields':
          return side === 'left' ? fieldsLeft : fieldsRight;
          case 'fieldGroups':
            return side === 'left' ? fieldGroupsLeft : fieldGroupsRight;
        case 'metrics':
          return side === 'left' ? metricsLeft : metricsRight;
        case 'assemblies':
          return side === 'left' ? assembliesLeft : assembliesRight;
        default:
          return [];
      }
    }
    return [];
  };

  const tableDataLeft = getTableData(selectedAccountIdLeft, selectedObjectTypeIdLeft, 'left');
  const tableDataRight = getTableData(selectedAccountIdRight, selectedObjectTypeIdRight, 'right');

  const [selectedComponentGroupIdLeft, setSelectedComponentGroupIdLeft] = useState(null);
  const [selectedComponentGroupIdRight, setSelectedComponentGroupIdRight] = useState(null);

  useEffect(() => {
  }, [selectedComponentGroupIdLeft]);
  
  useEffect(() => {
  }, [selectedComponentGroupIdRight]);  
  
  const handleComponentGroupChange = (componentGroupId, tableName) => {
    if (tableName === "left") {
      setSelectedComponentGroupIdLeft(componentGroupId);
    } else {
      setSelectedComponentGroupIdRight(componentGroupId);
    }
  };

  const [visibleColumnsLeft, setVisibleColumnsLeft] = useState({...defaultVisibleColumns});
  const [visibleColumnsRight, setVisibleColumnsRight] = useState({...defaultVisibleColumns});

  const handleColumnToggle = (tableName, key, defaultVisibleColumns = null) => {
    if (key === null && defaultVisibleColumns !== null) {
      if (tableName === "left") {
        setVisibleColumnsLeft(defaultVisibleColumns);
      } else {
        setVisibleColumnsRight(defaultVisibleColumns);
      }
      return;
    }
    
    if (tableName === "left") {
      setVisibleColumnsLeft({
        ...visibleColumnsLeft,
        [key]: !visibleColumnsLeft[key],
      });
    } else {
      setVisibleColumnsRight({
        ...visibleColumnsRight,
        [key]: !visibleColumnsRight[key],
      });
    }
  }; 

  const [draggedItem, setDraggedItem] = useState(null);
  const [dropTarget, setDropTarget] = useState(null);

  const handleDragStart = (e, item, draggedSide, matchingDataMap) => {
    setDraggedItem(item);
    e.dataTransfer.setData('item', JSON.stringify(item));
    e.dataTransfer.setData("draggedSide", draggedSide);
    e.dataTransfer.setData("matchingDataMap", JSON.stringify(matchingDataMap));
  };

  const handleDragEnter = (e, targetItem) => {
    setDropTarget(targetItem);
  };

  const handleDragLeave = (e, targetItem) => {
    setDropTarget(null);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    setDropTarget(null);
  };

  const handleDrop = async (e, targetItem, targetSide) => {
    const draggedItem = JSON.parse(e.dataTransfer.getData('item'));
    const draggedSide = e.dataTransfer.getData("draggedSide");
    const matchingDataMap = e.dataTransfer.getData("matchingDataMap");
    console.log('draggedItem:', draggedItem);
    console.log('draggedSide:', draggedSide);
    console.log('matchingDataMap:', matchingDataMap);

    setDropTarget(null);

    if (draggedSide === targetSide) {
      toast.error("Cannot drop a row onto another row in the same table.");
      return;
    }

    if (draggedItem.table !== targetItem.table) {
      toast.error("Cannot match items from different definition tables.");
      return;
    }

    const appropriateDataMaps = draggedSide === 'left' ? dataMapsLeft : dataMapsRight;
    const matchingDataMapForDragged = appropriateDataMaps.find(dataMap => dataMap.defId === draggedItem.id && dataMap.defTable === draggedItem.table);

    if (matchingDataMapForDragged && matchingDataMapForDragged.matchId) {
      const proceed = window.confirm("The row you're dragging already has a match. Do you want to proceed?");
      
      if (!proceed) {
        return;
      }

      // @todo - update the existing data map by storing notes, deleting the current datamap, and creating a new one
      console.log('matchingDataMapForDragged', matchingDataMapForDragged);
    }

    else {
      const dataMapToCreate = {
        accountId: draggedSide === 'left' ? selectedAccountIdLeft : selectedAccountIdRight,
        defTable: draggedItem.table,
        defId: draggedItem.id,
        matchId: targetItem.id,
        match: true,
      };
      
      const newMap = await dataMapService.createDataMap(dataMapToCreate);
      console.log('New Map:', newMap);
      if (newMap) {
        if (draggedSide === 'left') {
          setDataMapsLeft([...dataMapsLeft, newMap]);
        } else {
          setDataMapsRight([...dataMapsRight, newMap]);
        }

        toast.success("Data map created successfully.");
      } else {
        toast.error("Failed to create data map.");
      }
    }
  };

  const toggleDataMapProperty = async (property, itemId, matchingDataMap, tableName) => {
    const targetState = tableName === 'left' ? dataMapsLeft : dataMapsRight;
    const setTargetState = tableName === 'left' ? setDataMapsLeft : setDataMapsRight;
    const updatedDataMaps = JSON.parse(JSON.stringify(targetState));
    const targetMapIndex = updatedDataMaps.findIndex(map => map.id === matchingDataMap.id);
      
    if (targetMapIndex !== -1) {
      updatedDataMaps[targetMapIndex][property] = !updatedDataMaps[targetMapIndex][property];
    
      try {
        const updatedMap = await dataMapService.updateDataMap(updatedDataMaps[targetMapIndex]);
        toast.success("Data map updated successfully.");
        
        updatedDataMaps[targetMapIndex] = updatedMap;
        
        setTargetState(updatedDataMaps);
      } catch (error) {
        toast.error("Failed to update data map.");
      }
    }
  };
  
  const toggleMatchSourceTitle = async (itemId, matchingDataMap, tableName) => {
    toggleDataMapProperty('matchSourceTitle', itemId, matchingDataMap, tableName);
  };  

  const toggleApprove = (itemId, matchingDataMap, tableName) => {
    toggleDataMapProperty('approve', itemId, matchingDataMap, tableName);
  };

  const toggleDismiss = (itemId, matchingDataMap, tableName) => {
    toggleDataMapProperty('dismiss', itemId, matchingDataMap, tableName);
  };

  const createAndToggleDismiss = async (item, tableName) => {
      const dataMapToCreate = {
        accountId: tableName === 'left' ? selectedAccountIdLeft : selectedAccountIdRight,
        defTable: item.table,
        defId: item.id,
        dismiss: true,
      };
      
      const newMap = await dataMapService.createDataMap(dataMapToCreate);
      console.log('New Map:', newMap);
      if (newMap) {
        if (tableName === 'left') {
          setDataMapsLeft([...dataMapsLeft, newMap]);
        } else {
          setDataMapsRight([...dataMapsRight, newMap]);
        }

        toast.success("Data map created successfully.");
      } else {
        toast.error("Failed to create data map.");
      }
  };

  const handleTitle = (itemId, matchingDataMap, tableName) => {
    console.log('handleTitle', itemId, matchingDataMap, tableName);
  };

  const handleRemoveDataMap = async (dataMapId, matchingDataMap, tableName) => {
    try {
      await dataMapService.removeDataMap(dataMapId);
  
      const targetState = tableName === 'left' ? [...dataMapsLeft] : [...dataMapsRight];
  
      const updatedDataMaps = targetState.filter(dataMap => dataMap.id !== dataMapId);
  
      if (tableName === 'left') {
        setDataMapsLeft(updatedDataMaps);
      } else {
        setDataMapsRight(updatedDataMaps);
      }
  
      toast.success(`Successfully removed Data Map with id: ${dataMapId}`);
    } catch (error) {
      toast.error(`Failed to remove Data Map with id: ${dataMapId}: ${error}`);
    }
  };

  return (
    <div className="main-container">
      <ToastContainer />
      <div className="table-container">
        <ComparisonTable
          accountsAll={accountsAll}
          objectTypes={objectTypes}
          componentGroupsAll={componentGroupsAll}
          assemblies={assembliesLeft}
          componentGroups={componentGroupsLeft}
          components={componentsLeft}
          fieldGroups={fieldGroupsLeft}
          fields={fieldsLeft}
          lineItems={lineItemsLeft}
          metrics={metricsLeft}
          tableData={tableDataLeft}
          tableName="left"
          otherTableData={tableDataRight}
          dataMaps={dataMapsLeft}
          otherTableDataMaps={dataMapsRight}
          columnConfig={columnConfig}
          visibleColumns={visibleColumnsLeft}
          defaultVisibleColumns={defaultVisibleColumns}
          handleColumnToggle={handleColumnToggle}
          handleDragStart={handleDragStart}
          handleDragEnter={handleDragEnter}
          handleDragLeave={handleDragLeave}
          handleDragEnd={handleDragEnd}
          handleDrop={handleDrop}
          draggedItem={draggedItem}
          dropTarget={dropTarget}
          handleAccountChange={handleAccountChange}
          selectedAccountId={selectedAccountIdLeft}
          handleObjectTypeChange={handleObjectTypeChange}
          selectedObjectTypeId={selectedObjectTypeIdLeft}
          handleComponentGroupChange={handleComponentGroupChange}
          selectedComponentGroupId={selectedComponentGroupIdLeft}
          toggleMatchSourceTitle={toggleMatchSourceTitle}
          toggleApprove={toggleApprove}
          toggleDismiss={toggleDismiss}
          createAndToggleDismiss={createAndToggleDismiss}
          updateDataMaps={updateDataMaps}
          handleTitle={handleTitle}
          handleRemoveDataMap={handleRemoveDataMap}
        />
        <ComparisonTable
          accountsAll={accountsAll}
          objectTypes={objectTypes}
          componentGroupsAll={componentGroupsAll}
          assemblies={assembliesRight}
          componentGroups={componentGroupsRight}
          components={componentsRight}
          fieldGroups={fieldGroupsRight}
          fields={fieldsRight}
          lineItems={lineItemsRight}
          metrics={metricsRight}
          tableData={tableDataRight}
          tableName="right"
          otherTableData={tableDataLeft}
          dataMaps={dataMapsRight}
          otherTableDataMaps={dataMapsLeft}
          columnConfig={columnConfig}
          visibleColumns={visibleColumnsRight}
          defaultVisibleColumns={defaultVisibleColumns}
          handleColumnToggle={handleColumnToggle}
          handleDragStart={handleDragStart}
          handleDragEnter={handleDragEnter}
          handleDragLeave={handleDragLeave}
          handleDragEnd={handleDragEnd}
          handleDrop={handleDrop}
          draggedItem={draggedItem}
          dropTarget={dropTarget}
          handleAccountChange={handleAccountChange}
          selectedAccountId={selectedAccountIdRight}
          handleObjectTypeChange={handleObjectTypeChange}
          selectedObjectTypeId={selectedObjectTypeIdRight}
          handleComponentGroupChange={handleComponentGroupChange}
          selectedComponentGroupId={selectedComponentGroupIdRight}
          toggleMatchSourceTitle={toggleMatchSourceTitle}
          toggleApprove={toggleApprove}
          toggleDismiss={toggleDismiss}
          createAndToggleDismiss={createAndToggleDismiss}
          updateDataMaps={updateDataMaps}
          handleTitle={handleTitle}
          handleRemoveDataMap={handleRemoveDataMap}
        />
      </div>
      <div className="info-icon" onClick={handleInfoModalToggle}>
        <FontAwesomeIcon icon="info-circle" />
      </div>
      {isInfoModalOpen && (
        <div className="info-modal" onClick={handleInfoModalToggle}>
          <div className="info-modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={handleInfoModalToggle}>&times;</span>
            <h2>How Data Mapper Works</h2>
            <p>There are two tables. A "Source" table on the left and a "Target" table on the right.</p>
            <p>You can drag items from the "Target" table on the right to the "Source" table on the left to "Map" the items together.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
